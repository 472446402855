import { doc } from 'firebase/firestore';
import React from 'react';
import { Outlet, useParams } from 'react-router';
import styled from 'styled-components';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import Fake from 'web/components/elements/Fake';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Flare from 'web/components/Flare';
import SessionDateTime from 'web/components/session-page/SessionDateTime';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreDocumentData from 'web/hooks/useFirestoreDocumentData';
import themeClasses from 'web/styles/themeClasses.css';
import themeConstants from 'web/styles/themeConstants';
import { firestoreSessionConverter } from 'web/utils/convert';
import { formatCurrencyAmount } from 'web/utils/currency';
import { formatSessionDate, formatSessionTime } from 'web/utils/dateFormat';
import SessionContext from './SessionContex';
import Tooltip from 'web/components/Tooltip';

const SessionInfoPrice = styled.div`
  ${themeConstants.typography.h3}
`;

const SessionInfo = ({ session }: { session: introwise.Session }) => (
  <ContentColumn whiteBackground>
    <div className={themeClasses({ display: 'grid', gap: 4 })}>
      <div className={themeClasses({ display: 'flex', alignItems: 'center', flexWrap: 'wrap', columnGap: 2 })}>
        <h3 className={themeClasses({ flex: 'auto', margin: 0 })}>{session.title}</h3>
        {session.status === 'cancelled' ? (
          <div>
            <Flare variant="error">Cancelled</Flare>
          </div>
        ) : (
          session.rescheduledStart && (
            <div>
              <Tooltip
                label={`Rescheduled from ${formatSessionDate(session.rescheduledStart)}, ${formatSessionTime(
                  session.rescheduledStart,
                )}`}
              >
                <Flare variant="warning" style={{ cursor: 'help' }}>
                  Rescheduled
                </Flare>
              </Tooltip>
            </div>
          )
        )}
        <div>
          <SessionDateTime start={session.start} end={session.end} />
        </div>
      </div>
      {session.type === 'personal' ? (
        <>
          <div className={themeClasses({ display: 'flex', flexWrap: 'wrap' })}>
            <div className={themeClasses({ flex: 'auto' })}>
              {session.client.firstName} {session.client.lastName}
            </div>
            <SessionInfoPrice>{formatCurrencyAmount(session.totalPaymentAmount, session.currency)}</SessionInfoPrice>
          </div>
        </>
      ) : (
        <div className={themeClasses({ display: 'flex' })}>
          <div className={themeClasses({ flex: 'auto' })}>Group session</div>
          <SessionInfoPrice>{formatCurrencyAmount(session.totalPaymentAmount, session.currency)}</SessionInfoPrice>
        </div>
      )}
    </div>
  </ContentColumn>
);

const DashboardSessionsSessionContainer = () => {
  const firestore = useFirestore();
  const { sessionId } = useParams();

  const [session, loading, error] = useFirestoreDocumentData(
    doc(firestore, 'sessions', sessionId).withConverter(firestoreSessionConverter),
  );
  useErrorHandler(error);

  return (
    <>
      <>
        <BackLink to=".." />
        <ColumnContainer>
          <div>
            {loading && (
              <Fake height={16} animated>
                Loading...
              </Fake>
            )}
            {!loading && error && <ContentColumn whiteBackground>Error: {`${error}`}</ContentColumn>}
            {!loading && !error && !session && <ContentColumn whiteBackground>Session not found</ContentColumn>}
            {!loading && !error && session && (
              <>
                <SessionInfo session={session} />
                <SessionContext.Provider value={session}>
                  <Outlet />
                </SessionContext.Provider>
              </>
            )}
          </div>
        </ColumnContainer>
      </>
    </>
  );
};

export default DashboardSessionsSessionContainer;
