import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from 'web/components/elements';
import themeClasses from 'web/styles/themeClasses.css';
import WithOptionalLabel from './WithOptionalLabel';

const DateTimeSelector = ({
  disabled,
  isTimeOn,
  value,
  onChange,
  onBlur,
  hasError,
  startDate,
  endDate,
  selectsStart,
  selectsEnd,
  dateLabel,
  timeLabel,
}: {
  value: Date;
  onChange: (value: Date) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  hasError?: boolean;
  isTimeOn?: boolean;
  startDate?: Date;
  endDate?: Date;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  dateLabel?: string;
  timeLabel?: string;
}) => {
  const minDate = useMemo(() => new Date(), []);

  return (
    <div className={themeClasses({ display: 'flex', gap: 1 })}>
      <div>
        <WithOptionalLabel label={dateLabel}>
          <DatePicker
            selected={value}
            minDate={minDate}
            startDate={startDate}
            endDate={endDate}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            onChange={(date: Date | null) => {
              let newDate;
              if (value) {
                newDate = new Date(value);
                newDate.setDate(date.getDate());
                newDate.setMonth(date.getMonth());
                newDate.setFullYear(date.getFullYear());
              } else {
                newDate = new Date(date);
                newDate.setHours(11);
                newDate.setMinutes(0);
              }
              onChange(newDate);
            }}
            customInput={<Input hasError={hasError} />}
            name={`start`}
            onBlur={onBlur}
            placeholderText="Start date"
            autoComplete="off"
            disabled={disabled}
          />
        </WithOptionalLabel>
      </div>
      <div>
        {isTimeOn && (
          <WithOptionalLabel label={timeLabel}>
            <DatePicker
              selected={value}
              endDate={value}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              dateFormat="h:mm aa"
              onChange={(date: Date | null) => {
                const newDate = value ? new Date(value) : new Date();
                newDate.setHours(date.getHours());
                newDate.setMinutes(date.getMinutes());
                onChange(newDate);
              }}
              customInput={<Input hasError={hasError} />}
              name="start"
              onBlur={onBlur}
              placeholderText="12:00 AM"
              autoComplete="off"
              disabled={disabled}
            />
          </WithOptionalLabel>
        )}
      </div>
    </div>
  );
};

export default DateTimeSelector;
