import { doc, runTransaction } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Controller, FieldError, RegisterOptions, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import BookingPageContext from 'web/components/BookingPageContext';
import ColumnContainer from 'web/components/ColumnContainer';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import { Button, FormError, FormGroup, InlineButton } from 'web/components/elements';
import GroupSeriesSelector from 'web/components/packages/GroupSeriesSelector';
import PersonalSessionSelector from 'web/components/packages/PersonalSessionSelector';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import BookedPackageContext from './BookedPackageContext';
import BookedPackageInfo from './BookedPackageInfo';

type SubmitValues = {
  personalSessions: introwise.PackagePersonalSessions;
  groupSessionSeries: introwise.PackageGroupSessionSeries;
};

const sessionCountValidation: RegisterOptions = {
  validate: (value: SubmitValues['personalSessions'] | SubmitValues['groupSessionSeries']) => {
    return Object.values(value).every((v) => v.count >= 0) || 'Sessions count must be a positive number';
  },
};

const PackageSessionsForm = ({
  currency,
  pack,
  services,
  series,
  onSubmit,
  onCancel,
  submitting,
}: {
  currency: introwise.Currency;
  services: { [id: string]: introwise.Service };
  series: { [id: string]: introwise.Series };
  pack: Pick<introwise.Package, 'personalSessions' | 'groupSessionSeries'>;
  onSubmit: (values: SubmitValues) => void;
  onCancel: () => void;
  submitting: boolean;
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SubmitValues>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={submitting}>
        <h4 className={themeClasses({ marginBottom: 0 })}>Included services</h4>
        <FormGroup>
          <Controller
            name="personalSessions"
            control={control}
            defaultValue={pack.personalSessions || {}}
            rules={sessionCountValidation}
            render={({ field }) => (
              <PersonalSessionSelector
                personalSessions={field.value}
                onChange={field.onChange}
                services={services}
                currency={currency}
              />
            )}
          />
          {errors.personalSessions && <FormError>{(errors.personalSessions as FieldError).message}</FormError>}
        </FormGroup>
        <h4 className={themeClasses({ marginBottom: 0 })}>Included group sessions series</h4>
        <FormGroup>
          <Controller
            name="groupSessionSeries"
            control={control}
            defaultValue={pack.groupSessionSeries || {}}
            rules={sessionCountValidation}
            render={({ field }) => (
              <GroupSeriesSelector groupSessionSeries={field.value} onChange={field.onChange} series={series} />
            )}
          />
          {errors.groupSessionSeries && <FormError>{(errors.groupSessionSeries as FieldError).message}</FormError>}
        </FormGroup>
        <FormGroup className={themeClasses({ display: 'flex', alignItems: 'center', justifyContent: 'space-between' })}>
          <Button type="submit" variant="primary">
            Save
          </Button>
          <InlineButton onClick={onCancel} disabled={submitting}>
            Cancel
          </InlineButton>
        </FormGroup>
      </fieldset>
    </form>
  );
};

const DashboardPackagesPackageEdit = () => {
  const bookedPackage = useContext(BookedPackageContext);
  const [page, , pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);
  const firestore = useFirestore();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const navigate = useNavigate();

  const save = async (values: SubmitValues) => {
    setSubmitting(true);
    setError(undefined);
    try {
      await runTransaction(firestore, async (transaction) => {
        const bookedPackageDoc = await transaction.get(doc(firestore, 'bookedPackages', bookedPackage.id));

        if (!bookedPackageDoc.exists()) {
          throw new Error('Booked package not found');
        }

        const bookedPackageData = bookedPackageDoc.data() as introwise.BookedPackage;

        const update: introwise.FirestoreUpdateData<introwise.BookedPackage> = {
          'package.personalSessions': values.personalSessions || {},
          'package.groupSessionSeries': values.groupSessionSeries || {},
        };

        for (const [serviceId, personalSession] of Object.entries(values.personalSessions || {})) {
          if (bookedPackageData.booked.personal[serviceId]) {
            if (bookedPackageData.package.personalSessions[serviceId].count !== personalSession.count) {
              update[`booked.personal.${serviceId}.available`] = Math.max(
                0,
                personalSession.count - bookedPackageData.booked.personal[serviceId].sessionIds.length,
              );
            } else {
              // Nothing to update
            }
          } else {
            // New personal session service
            update[`booked.personal.${serviceId}`] = {
              serviceId,
              available: values.personalSessions[serviceId].count,
              sessionIds: [],
            };
          }
        }

        for (const [serviceId] of Object.entries(bookedPackageData.booked.personal || {})) {
          if (!values.personalSessions[serviceId]) {
            // Removed personal session service
            update[`booked.personal.${serviceId}.available`] = 0;
          }
        }

        for (const [seriesId, groupSessionSeries] of Object.entries(values.groupSessionSeries || {})) {
          if (bookedPackageData.booked.groupSeries[seriesId]) {
            if (bookedPackageData.package.groupSessionSeries[seriesId].count !== groupSessionSeries.count) {
              update[`booked.groupSeries.${seriesId}.available`] = Math.max(
                0,
                groupSessionSeries.count - bookedPackageData.booked.groupSeries[seriesId].sessionIds.length,
              );
            } else {
              // Nothing to update
            }
          } else {
            // New group session series
            update[`booked.groupSeries.${seriesId}`] = {
              seriesId,
              available: values.groupSessionSeries[seriesId].count,
              sessionIds: [],
            };
          }
        }

        for (const [seriesId] of Object.entries(bookedPackageData.booked.groupSeries || {})) {
          if (!values.groupSessionSeries[seriesId]) {
            // Removed group session series
            update[`booked.groupSeries.${seriesId}.available`] = 0;
          }
        }

        transaction.update(doc(firestore, 'bookedPackages', bookedPackage.id), update);
      });
      setSubmitting(false);
      navigate('..', { replace: true });
    } catch (e) {
      setError(e.message);
    }
    setSubmitting(false);
  };

  return (
    <>
      <BackLink to="../.." />
      <ColumnContainer>
        <div>
          <BookedPackageInfo bookedPackage={bookedPackage} />
          <WithHeaderContentColumn header="Edit a package" whiteBackground>
            <div>
              <PackageSessionsForm
                services={page.services}
                series={page.series}
                currency={page.currency}
                pack={bookedPackage.package}
                onSubmit={save}
                onCancel={() => navigate('..', { replace: true })}
                submitting={submitting}
              />
              {error && <FormError>Something went wrong. Please try again later.</FormError>}
            </div>
          </WithHeaderContentColumn>
        </div>
      </ColumnContainer>
    </>
  );
};

export default DashboardPackagesPackageEdit;
