import Tooltip from 'web/components/Tooltip';
import React from 'react';
import ContentColumn from 'web/components/ContentColumn';
import { AnchorStyled, LinkStyled } from 'web/components/elements';
import Flare from 'web/components/Flare';
import sc from 'web/components/styled';
import themeClasses from 'web/styles/themeClasses.css';
import themeConstants from 'web/styles/themeConstants';
import { formatCurrencyAmount } from 'web/utils/currency';

const PackageInfoPrice = sc.div(undefined, themeConstants.typography.h3);

const formatDiscount = (discount: introwise.DiscountCodeFixed | introwise.DiscountCodePercentage) =>
  discount.valueType === 'percentage'
    ? `${discount.value.percentOff}% off`
    : `${formatCurrencyAmount(discount.value.amountOff, discount.value.currency)} off`;

const BookedPackageInfo = ({ bookedPackage }: { bookedPackage: introwise.BookedPackage }) => (
  <ContentColumn whiteBackground>
    <div className={themeClasses({ display: 'flex', flexWrap: 'wrap' })}>
      <div className={themeClasses({ flex: 'auto' })}>
        <h3 className={themeClasses({ margin: 0 })}>{bookedPackage.package.title}</h3>
        <div>
          {bookedPackage.client.id ? (
            <LinkStyled to={`/dashboard/home/clients/${bookedPackage.client.id}`}>
              {bookedPackage.client.firstName} {bookedPackage.client.lastName}{' '}
            </LinkStyled>
          ) : (
            <b>
              {bookedPackage.client.firstName} {bookedPackage.client.lastName}{' '}
            </b>
          )}
          <br />
          <AnchorStyled href={`mailto:${bookedPackage.client.email}`}>{bookedPackage.client.email}</AnchorStyled>
        </div>
      </div>
      <div className={themeClasses({ textAlign: { all: 'left', sm: 'right' } })}>
        <PackageInfoPrice>{formatCurrencyAmount(bookedPackage.paymentAmount, bookedPackage.currency)}</PackageInfoPrice>
        <div>
          {bookedPackage.paymentAmount > 0 &&
            (bookedPackage.paymentId ? (
              <Flare variant="success">Paid</Flare>
            ) : (
              <Tooltip label="Payment was not collected on booking">
                <Flare variant="warning">Unpaid</Flare>
              </Tooltip>
            ))}
          {bookedPackage.discount && (
            <>
              {bookedPackage.paymentAmount > 0 && <br />}
              <LinkStyled to={`/dashboard/payments/discounts/${bookedPackage.discountCodeId}`}>
                {formatDiscount(bookedPackage.discount)}
              </LinkStyled>
            </>
          )}
        </div>
      </div>
    </div>
    {bookedPackage.client.message && <p>{bookedPackage.client.message}</p>}
  </ContentColumn>
);

export default BookedPackageInfo;
