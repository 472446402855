import { doc } from 'firebase/firestore';
import React from 'react';
import { Outlet, useParams } from 'react-router';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreDocumentData from 'web/hooks/useFirestoreDocumentData';
import { firestoreBookedPackageConverter } from 'web/utils/convert';
import BookedPackageContext from './BookedPackageContext';

const DashboardPackagesPackageContainer = () => {
  const firestore = useFirestore();
  const { packageId } = useParams();

  const [bookedPackage, loading, error] = useFirestoreDocumentData(
    doc(firestore, 'bookedPackages', packageId).withConverter(firestoreBookedPackageConverter),
  );

  useErrorHandler(error);

  return (
    <>
      {loading && <p>Loading...</p>}
      {!loading && error && <p>Error: {`${error}`}</p>}
      {!loading && !error && !bookedPackage && <p>Not found</p>}
      {!loading && !error && bookedPackage && (
        <BookedPackageContext.Provider value={bookedPackage}>
          <Outlet />
        </BookedPackageContext.Provider>
      )}
    </>
  );
};

export default DashboardPackagesPackageContainer;
