import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'web/components/Tooltip';
import { collection, doc, increment, runTransaction, serverTimestamp } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { To } from 'react-router-dom';
import {
  Button,
  FormDescription,
  FormError,
  FormFootnote,
  FormGroup,
  InlineButton,
  Label,
  LabelText,
  LinkButton,
  Table,
  TextArea,
} from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import DateTimeSelector from 'web/components/form-fields/DateTimeSelector';
import FormValueLength from 'web/components/form-fields/FormValueLength';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import useTracking from 'web/components/TrackingContext/useTracking';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import themeClasses from 'web/styles/themeClasses.css';
import themeVars from 'web/styles/themeVars.css';
import { firestoreBookingConverter, firestoreSessionConverter } from 'web/utils/convert';
import SessionContext from './SessionContex';

const maxMessageLength = 800;

const formatTimezone = (timezone: string) => timezone?.replace('_', ' ') || 'Unknown';

const TimezoneTooltip = () => (
  <Tooltip label="Timezone is estimated from the client's booking">
    <span>
      <FontAwesomeIcon icon={faInfoCircle} fixedWidth style={{ color: themeVars.color.muted }} />
    </span>
  </Tooltip>
);

const TimezoneInfo = () => {
  const timezone = useMemo(() => formatTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone), []);
  return <FormDescription>Your timezone: {timezone}</FormDescription>;
};

const formatDate = (date: Date, timeZone: string) =>
  date.toLocaleString('en', {
    timeZone,
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

const ClientTimesTable = ({ start, sessionId }: { start: Date; sessionId: string }) => {
  const firestore = useFirestore();
  const [bookings, loading, error] = useFirestoreCollectionData(
    collection(firestore, 'sessions', sessionId, 'bookings').withConverter(firestoreBookingConverter),
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <FormError>Something went wrong. Cannot load session bookings.</FormError>;
  }

  return (
    <div style={{ maxHeight: 300, overflow: 'auto' }}>
      <Table>
        <thead>
          <tr
            style={{
              position: 'sticky',
              top: 0,
              background: themeVars.backgrounds.content,
            }}
          >
            <th>Client</th>
            <th>New time</th>
            <th>
              <span>Timezone</span> <TimezoneTooltip />
            </th>
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking) => (
            <tr key={booking.id}>
              <td>{`${booking.client.firstName} ${booking.client.lastName}`}</td>
              <td>{formatDate(start, booking.client.timezone)}</td>
              <td>{formatTimezone(booking.client.timezone)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const ClientTimes = ({
  start,
  bookingsCount,
  client,
  sessionId,
}: {
  start: Date;
  bookingsCount: number;
  client: introwise.PersonalSession['client'];
  sessionId: string;
}) => {
  const [showClientTimes, setShowClientTimes] = useState(false);
  return bookingsCount === 1 && client ? (
    <>
      <div>
        New local time for {`${client.firstName} ${client.lastName}`}: <b>{formatDate(start, client.timezone)}</b>
      </div>
      <FormDescription>
        Client&apos;s timezone: {formatTimezone(client.timezone)} <TimezoneTooltip />
      </FormDescription>
    </>
  ) : showClientTimes ? (
    <ClientTimesTable start={start} sessionId={sessionId} />
  ) : (
    <InlineButton onClick={() => setShowClientTimes(true)}>
      Check the new time in your clients&apos; timezones
    </InlineButton>
  );
};

const SessionRescheduleForm = ({
  initialStart,
  onSubmit,
  error,
  submitting,
  onCancelTo,
  bookingsCount,
  sessionId,
  client,
}: {
  initialStart: Date;
  onSubmit: (val: { start: Date; message: string }) => void;
  error: string | Error;
  submitting: boolean;
  onCancelTo: To;
  bookingsCount: number;
  sessionId: string;
  client?: introwise.PersonalSession['client'];
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<{ message: string; start: Date }>({ defaultValues: { message: '', start: initialStart } });

  const start = watch('start');

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p style={{ margin: 0 }}>
          Choose a new time for your session
          {bookingsCount > 0 ? <> and include an optional rescheduling message</> : ''}
        </p>
        <FormGroup>
          <div style={{ maxWidth: 300 }}>
            <Controller
              name="start"
              control={control}
              rules={{
                required: 'Required',
                validate: {
                  notSameAsInitial: (value) =>
                    value?.getTime() === initialStart?.getTime()
                      ? 'Cannot be the same as the original time'
                      : undefined,
                  startMin: (value) => (value < new Date() ? 'Start date must be in the future' : undefined),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <DateTimeSelector
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isTimeOn
                  disabled={submitting}
                  hasError={!!errors.start}
                />
              )}
            />
          </div>
          <TimezoneInfo />
          {errors.start && <FormError>{errors.start.message}</FormError>}
        </FormGroup>
        {bookingsCount > 0 ? (
          <>
            <FormGroup>
              <Label>
                <LabelText>Message (optional)</LabelText>
                <TextArea
                  name="message"
                  rows={4}
                  {...register('message', {
                    setValueAs: (v) => v.trim(),
                    maxLength: {
                      value: maxMessageLength,
                      message: `Too long, please limit the message to ${maxMessageLength} characters`,
                    },
                  })}
                />
              </Label>
              <FormFootnote>
                <FormValueLength control={control} name="message" maxLength={maxMessageLength} />
              </FormFootnote>
              <FormError>{errors.message ? errors.message.message : <>&nbsp;</>}</FormError>
              <div style={{ marginBottom: '-0.75em' }} />
            </FormGroup>
            <FormGroup>
              <ClientTimes start={start} client={client} bookingsCount={bookingsCount} sessionId={sessionId} />
            </FormGroup>
          </>
        ) : (
          <p>No one has booked this session yet. No rescheduling emails will be sent.</p>
        )}
        <FormGroup
          style={{ gridAutoColumns: '1fr' }}
          className={themeClasses({
            display: 'grid',
            gridAutoFlow: { all: 'row', sm: 'column' },
            gap: 5,
            textAlign: 'center',
          })}
        >
          <LinkButton secondary to={onCancelTo}>
            I&apos;ve changed my mind
          </LinkButton>
          <Button primary type="submit" disabled={submitting}>
            {submitting && <Spinner />}
            <span>Confirm rescheduling</span>
          </Button>
        </FormGroup>
      </form>
      {error && <FormError>{`${error}`}</FormError>}
    </>
  );
};

const SessionRescheduleSuccess = ({ message, bookingsCount }: { message: string; bookingsCount: number }) => (
  <>
    <b>Session has been rescheduled.</b>

    {bookingsCount > 0 && (
      <>
        {message && (
          <>
            <>
              {' '}
              {bookingsCount === 1 ? 'Your client' : 'Clients'} will receive a rescheduling confirmation with your
              message:
            </>
            <p className={themeClasses({ wordBreak: 'break-word', whiteSpace: 'pre-line' })}>
              <i>{message}</i>
            </p>
          </>
        )}
        {!message && <> {bookingsCount === 1 ? 'Your client' : 'Clients'} will receive a rescheduling confirmation.</>}
      </>
    )}
    <div className={themeClasses({ marginTop: 9 })}>
      <LinkButton to="/dashboard/home/sessions">Go to dashboard</LinkButton>
    </div>
  </>
);

const DashboardSessionsSessionReschedule = () => {
  const firestore = useFirestore();
  const tracking = useTracking();

  const [error, setError] = useErrorStateHandler();

  const [message, setMessage] = useState('');
  const [rescheduled, setRescheduled] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const session = useContext(SessionContext);
  const bookingsCount = session.type === 'personal' ? 1 : session.groupSize.current;

  const onSubmit = async ({ message: msg, start: newStart }: { message: string; start: Date }) => {
    setSubmitting(true);

    setMessage(msg);

    const duration = session.end.getTime() - session.start.getTime();
    const newEnd = new Date(newStart.getTime() + duration);

    try {
      const sessionRef = doc(firestore, 'sessions', session.id).withConverter(firestoreSessionConverter);

      await runTransaction(firestore, async (t) => {
        const sessionDoc = await t.get(sessionRef);
        if (!sessionDoc.exists) {
          throw new Error(`Session doesn't exist`);
        }
        if (sessionDoc.data().status === 'cancelled') {
          throw new Error(`Session is cancelled`);
        }
        return t.update(sessionRef, {
          start: newStart,
          end: newEnd,
          rescheduledAt: serverTimestamp(),
          rescheduledBy: null,
          rescheduledStart: session.start,
          rescheduledEnd: session.end,
          reschedulingMessage: msg,
          iCalSequence: increment(1),
        });
      });
      tracking.trackEvent('Session Rescheduled');
      setRescheduled(true);
    } catch (err) {
      setError(err);
    }

    setSubmitting(false);
  };

  return (
    <>
      <Helmet title="Session rescheduling" />
      <ScreenTracker screenName="SessionRescheduling" />
      <WithHeaderContentColumn header="Rescheduling" whiteBackground>
        {!rescheduled ? (
          <SessionRescheduleForm
            initialStart={session.start}
            onSubmit={onSubmit}
            submitting={submitting}
            error={error}
            bookingsCount={bookingsCount}
            client={session.type === 'personal' && session.client}
            sessionId={session.id}
            onCancelTo=".."
          />
        ) : (
          <SessionRescheduleSuccess message={message} bookingsCount={bookingsCount} />
        )}
      </WithHeaderContentColumn>
    </>
  );
};

export default DashboardSessionsSessionReschedule;
