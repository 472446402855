import { collection, doc, documentId, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';
import BackLink from 'web/components/BackLink';
import BookingPageContext from 'web/components/BookingPageContext';
import BookingPageLink from 'web/components/BookingPageLink';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import { Button, InlineButton, LinkButton, LinkStyled, Table } from 'web/components/elements';
import Fake from 'web/components/elements/Fake';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Flare from 'web/components/Flare';
import GenericReactModal from 'web/components/GenericReactModal';
import { SessionsList } from 'web/components/session-page/SessionsList';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import { useDocumentData } from 'web/hooks/firebase';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import themeClasses from 'web/styles/themeClasses.css';
import { firestorePaymentPlanConverter, firestoreSessionConverter } from 'web/utils/convert';
import { formatCurrencyAmount } from 'web/utils/currency';
import { formatSessionDate, formatSessionDateLong } from 'web/utils/dateFormat';
import BookedPackageContext from './BookedPackageContext';
import BookedPackageInfo from './BookedPackageInfo';

const maxQueryArrayLength = 10;

const PackageSessions = ({ sessionIds }: { sessionIds: string[] }) => {
  const firestore = useFirestore();
  const sessionIdsReverse = useMemo(() => sessionIds.slice().reverse(), [sessionIds]);

  const [offset, setOffset] = useState(0);

  const idsToQuery = useMemo(
    () => sessionIdsReverse.slice(offset, offset + maxQueryArrayLength),
    [sessionIdsReverse, offset],
  );

  const hasNext = sessionIds?.length > offset + maxQueryArrayLength;
  const hasPrev = offset > 0;

  const [sessions, loading, error] = useFirestoreCollectionData(
    query(collection(firestore, 'sessions'), where(documentId(), 'in', idsToQuery)).withConverter(
      firestoreSessionConverter,
    ),
  );

  const sessionsSorted = useMemo(
    () => (sessions ? sessions.sort((a, b) => b.start.getTime() - a.start.getTime()) : []),
    [sessions],
  );

  useErrorHandler(error);

  return (
    <>
      <SessionsList
        sessions={sessionsSorted}
        loading={loading}
        past
        useTitle
        noSessionsElement={<ContentColumn whiteBackground>No sessions booked</ContentColumn>}
      />
      {(hasPrev || hasNext) && (
        <div className={themeClasses({ display: 'flex', justifyContent: 'space-between', marginTop: 5 })}>
          {hasPrev ? (
            <Button
              sm
              secondary
              onClick={() => setOffset((prevOffset) => Math.max(0, prevOffset - maxQueryArrayLength))}
            >
              Previous
            </Button>
          ) : (
            <div />
          )}
          {hasNext ? (
            <Button sm secondary onClick={() => setOffset((prevOffset) => prevOffset + maxQueryArrayLength)}>
              Next
            </Button>
          ) : (
            <div />
          )}
        </div>
      )}
    </>
  );
};

const PackageServices = ({ bookedPackage, page }: { bookedPackage: introwise.BookedPackage; page: introwise.Page }) => {
  const serviceIds = Object.keys(bookedPackage.package.personalSessions);

  const completed =
    serviceIds.length === 0 ||
    serviceIds.reduce((acc, serviceId) => acc + bookedPackage.booked.personal[serviceId].available, 0) === 0;

  const serviceIdsSorted = useMemo(
    () => serviceIds.sort((a, b) => (page.services?.[a]?.order || 0) - (page.services?.[b]?.order || 0)),
    [serviceIds, page],
  );

  return (
    <WithHeaderContentColumn
      header="Services included"
      whiteBackground
      extendedHeader={
        <div style={{ textAlign: 'right' }}>
          {completed ? (
            <Flare variant="success">Completed</Flare>
          ) : (
            bookedPackage.expiresAt && (
              <Flare variant={bookedPackage.expiresAt < new Date() ? 'error' : 'warning'}>
                Expire{bookedPackage.expiresAt < new Date() ? 'd' : 's'} on {formatSessionDate(bookedPackage.expiresAt)}
              </Flare>
            )
          )}
        </div>
      }
    >
      {serviceIdsSorted.length === 0 ? (
        <p>This package doesn&apos;t include any services</p>
      ) : (
        <div style={{ overflowX: 'auto' }}>
          <Table>
            <thead>
              <tr>
                <th>Service</th>
                <th>Usage</th>
              </tr>
            </thead>
            <tbody>
              {serviceIdsSorted.map((serviceId) => (
                <tr key={serviceId}>
                  <td>
                    <LinkStyled to={`/dashboard/scheduling/services/${serviceId}`}>
                      {page.services?.[serviceId]?.title}
                    </LinkStyled>
                  </td>
                  <td>
                    {bookedPackage.booked.personal[serviceId].available} of{' '}
                    {bookedPackage.package.personalSessions[serviceId].count} remaining
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </WithHeaderContentColumn>
  );
};

const PackageSeries = ({ bookedPackage, page }: { bookedPackage: introwise.BookedPackage; page: introwise.Page }) => {
  const seriesIds = Object.keys(bookedPackage.package.groupSessionSeries);

  const completed =
    seriesIds.length === 0 ||
    seriesIds.reduce((acc, seriesId) => acc + bookedPackage.booked.groupSeries[seriesId].available, 0) === 0;

  const seriesIdsSorted = useMemo(
    () => seriesIds.sort((a, b) => (page.series?.[a]?.order || 0) - (page.series?.[b]?.order || 0)),
    [seriesIds, page],
  );

  return (
    <WithHeaderContentColumn
      header="Series included"
      whiteBackground
      extendedHeader={
        <div style={{ textAlign: 'right' }}>
          {completed ? (
            <Flare variant="success">Completed</Flare>
          ) : (
            bookedPackage.expiresAt && (
              <Flare variant={bookedPackage.expiresAt < new Date() ? 'error' : 'warning'}>
                Expire{bookedPackage.expiresAt < new Date() ? 'd' : 's'} on {formatSessionDate(bookedPackage.expiresAt)}
              </Flare>
            )
          )}
        </div>
      }
    >
      {seriesIdsSorted.length === 0 ? (
        <p>This package doesn&apos;t include any group sessions series</p>
      ) : (
        <div style={{ overflowX: 'auto' }}>
          <Table>
            <thead>
              <tr>
                <th>Series</th>
                <th>Usage</th>
              </tr>
            </thead>
            <tbody>
              {seriesIdsSorted.map((seriesId) => (
                <tr key={seriesId}>
                  <td>
                    <LinkStyled to={`/dashboard/scheduling/series/${seriesId}`}>
                      {page.series?.[seriesId]?.title}
                    </LinkStyled>
                  </td>
                  <td>
                    {bookedPackage.booked.groupSeries[seriesId].available} of{' '}
                    {bookedPackage.package.groupSessionSeries[seriesId].count} remaining
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </WithHeaderContentColumn>
  );
};

const AccessLinkButton = ({ bookedPackageId }: { bookedPackageId: string }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <GenericReactModal isOpen={open} onRequestClose={() => setOpen(false)}>
        <div style={{ width: 480, maxWidth: '80vw', maxHeight: '90vh', overflowY: 'auto' }}>
          <h3 className={themeClasses({ marginTop: 0 })}>Access link</h3>
          <p>
            The client has received a link to their email address that gives them access to the package. This link can
            be used to book sessions and see the remaining package credits at any time.
          </p>
          <p>
            If your client has lost the link, you can share the link below with them. You can use this link yourself to
            book sessions included in this package on behalf of your client.
          </p>
          <div>
            <BookingPageLink path={`/packages/use?packageId=${bookedPackageId}`} active={false} />
          </div>
        </div>
      </GenericReactModal>
      <Button onClick={() => setOpen(true)} size="md" variant="secondary">
        View access link
      </Button>
    </>
  );
};

const PaymentPlan = ({ pageId, paymentPlanScheduledId }: { pageId: string; paymentPlanScheduledId: string }) => {
  const firestore = useFirestore();
  const [paymentPlan, loading, error] = useDocumentData(
    doc(firestore, 'pages', pageId, 'paymentPlans', paymentPlanScheduledId).withConverter(
      firestorePaymentPlanConverter,
    ),
  );
  const errorReporter = useErrorReporter();

  const sortedPayments = useMemo(
    () =>
      paymentPlan?.payments
        ? Object.values(paymentPlan.payments).sort((a, b) => a.dueAt.getTime() - b.dueAt.getTime())
        : null,
    [paymentPlan?.payments],
  );

  const cancelPlan = async () => {
    const res = window.confirm('Are you sure you want to cancel this payment plan?');
    if (!res) {
      return;
    }

    try {
      await updateDoc(doc(firestore, 'pages', pageId, 'paymentPlans', paymentPlanScheduledId), {
        status: 'cancelled',
        cancelledAt: serverTimestamp(),
      });
    } catch (e) {
      errorReporter.report(new Error(`Error cancelling payment plan: ${e}`));
      window.alert(`Something went wrong with cancelling the payment plan. Please try again later.`);
    }
  };

  return (
    <WithHeaderContentColumn
      header="Payment plan"
      whiteBackground
      extendedHeader={
        !loading &&
        !error &&
        paymentPlan && (
          <div className={themeClasses({ display: 'flex', alignItems: 'center' })} style={{ height: 32 }}>
            {paymentPlan.status === 'active' && <InlineButton onClick={cancelPlan}>Cancel</InlineButton>}
            {paymentPlan.status === 'cancelled' && <Flare variant="error">Cancelled</Flare>}
            {paymentPlan.status === 'completed' && <Flare variant="success">Completed</Flare>}
          </div>
        )
      }
    >
      {loading && <Fake />}
      {error && <div>{`${error}`}</div>}
      {!loading && !error && paymentPlan && (
        <Table>
          <thead>
            <tr>
              <th>Due</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {sortedPayments.map((payment) => (
              <tr key={payment.id}>
                <td>{formatSessionDateLong(payment.dueAt)}</td>
                <td>{formatCurrencyAmount(payment.amount, paymentPlan.currency)}</td>
                <td>
                  {payment.status === 'pending' && <Flare variant="disabled">Pending</Flare>}
                  {payment.status === 'paid' && <Flare variant="success">Paid</Flare>}
                  {payment.status === 'failed' && <Flare variant="error">Failed</Flare>}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </WithHeaderContentColumn>
  );
};

const DashboardPackagesPackage = () => {
  const bookedPackage = useContext(BookedPackageContext);
  const [page, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);

  return (
    <>
      <BackLink to="../.." />
      <ColumnContainer>
        <div>
          <BookedPackageInfo bookedPackage={bookedPackage} />
          <WithHeaderContentColumn header="Sessions booked">
            {bookedPackage.booked.sessionIds && bookedPackage.booked.sessionIds.length > 0 ? (
              <PackageSessions sessionIds={bookedPackage.booked.sessionIds} />
            ) : (
              <ContentColumn whiteBackground>No sessions booked</ContentColumn>
            )}
          </WithHeaderContentColumn>
          {!pageLoading && page && (
            <>
              {bookedPackage.package.personalSessions && <PackageServices bookedPackage={bookedPackage} page={page} />}
              {bookedPackage.package.groupSessionSeries && <PackageSeries bookedPackage={bookedPackage} page={page} />}
            </>
          )}
          {bookedPackage.paymentPlanScheduledId && (
            <PaymentPlan pageId={page.id} paymentPlanScheduledId={bookedPackage.paymentPlanScheduledId} />
          )}
          <WithHeaderContentColumn header="Manage" whiteBackground>
            <div className={themeClasses({ display: 'flex', gap: 4 })}>
              <AccessLinkButton bookedPackageId={bookedPackage.id} />
              <LinkButton size="md" variant="secondary" to="edit">
                Edit package
              </LinkButton>
              {bookedPackage.expiresAt && (
                <LinkButton to="expiration" size="md" variant="secondary">
                  Manage expiration
                </LinkButton>
              )}
              <LinkButton to="/contact" size="md" variant="secondary">
                Get support
              </LinkButton>
            </div>
          </WithHeaderContentColumn>
        </div>
      </ColumnContainer>
    </>
  );
};

export default DashboardPackagesPackage;
